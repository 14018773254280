export function IconShares() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.14286 7.57143V5H5.85714V7.57143H8V16.8571H5.85714V20H5.14286V16.8571H3V7.57143H5.14286Z"
        stroke="#0A0A0A"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M16.5714 6.08571V3H17.4286V6.08571H20V17.2286H17.4286V21H16.5714V17.2286H14V6.08571H16.5714Z"
        stroke="#0A0A0A"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}
